<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="common-body-720-90 entrance-body">
        <div v-if="eventId">
          <div class="cWhite">
            <!-- <div class="entrance-title">まもなく{{ eventExpress }}本番！</div> -->
            <div class="entrance-content">
              <p>
                注意事項を読んだ上で同意して次に進んでください。<br />
                次にチャットルームへ入室できるようになり、ファン同士での交流をお楽しみください。
              </p>

              <p>
                【注意事項】<br />
                {{ eventExpress }}開始15分前から受付開始となり、{{
                  eventExpress
                }}開始まで必ずチャットルームでお待ちください。<br /><br />
                {{
                  eventExpress
                }}開始1分前になると自動的に待合室に入ります。画面は開いたまま、アプリ同士の切り替えを控えください。ネットワーク不安定を招く原因となります。<br />
                やむを得ない理由で離れ、お戻りになられた後、カメラに映像が映らないことが発生しましたら、{{
                  eventExpress
                }}ページを【再度読み込み】してみてください。エラーが発生した場合は申込一覧から再度お申込を行ってください。
              </p>

              <p>
                【禁止事項】<br />
                誹謗中傷、罵声、その他嫌がらせと取られる行為、デバイスの録画機能、スクリーンショットのご使用はおやめください。<br />
                品質向上のため一部記録させていただきます。ご理解のほどよろしくお願い致します。
              </p>
            </div>
          </div>
          <div class="entrance-end-btn-body">
            <button type="button" class="entrance-end-btn" @click="goNext">
              同意して次へ
            </button>
          </div>
        </div>
        <div class="common-loading flexCenter dflex" v-if="loadingData">
          <div class="common-authing-body">
            <div class="common-authing item7">
              <div class="dot dot1"></div>
              <div class="dot dot2"></div>
              <div class="dot dot3"></div>
            </div>
            <div class="tc">認証中</div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  chevronBackOutline,
  bookmarkSharp,
  chevronForwardOutline,
  logOutOutline
} from 'ionicons/icons'
import { authApi } from '../../../api'
import { commonLogin, addRefreshJWT } from '../../../utils/utils'

export default defineComponent({
  name: 'Entrance',
  data() {
    return {
      chevronBackOutline,
      bookmarkSharp,
      chevronForwardOutline,
      logOutOutline,
      authcode: this.$route.query.authcode,
      loadingData: true,
      eventId: null
    }
  },
  components: {
    IonContent,
    IonPage
  },
  ionViewWillEnter() {
    this.init()
  },
  methods: {
    goError() {
      this.$toast.error('認証に失敗しました')
      this.$router.replace({ name: 'Error' })
    },
    goNext() {
      this.$router.replace({
        path: `/user/meeting/${this.eventId}/chatroom/`
      })
    },
    getJoinedInfo() {
      authApi
        .joined()
        .then(res => {
          console.log(res)
          if (res.data.length > 0) {
            this.$store.dispatch('setEventExpress', res.data[0].eventExpress)
            this.eventId = res.data[0].id
          } else {
            this.goError()
          }
          this.loadingData = false
        })
        .catch(error => {
          this.loadingData = false
          const status = error.response.status
          switch (status) {
            case 401:
              addRefreshJWT(this.getJoinedInfo)
              break
            default:
              this.goError()
              break
          }
        })
    },
    init() {
      this.loadingData = true
      this.eventId = null
      if (!this.authcode) {
        this.loadingData = false
        this.goError()
        return false
      }
      const params = {
        provider: 'authcode',
        token: this.authcode,
        userid: 'authcode'
      }
      authApi
        .login(params)
        .then(loginRes => {
          console.log(loginRes)
          if (loginRes.data.jwt) {
            // jwtがある場合直接ログインする
            commonLogin(loginRes.data.jwt, 'c', this.getJoinedInfo())
          }
        })
        .catch(() => {
          if (this.$store.getters.token) {
            this.getJoinedInfo()
          } else {
            this.loadingData = false
            this.goError()
          }
        })
    }
  },
  computed: {
    eventExpress() {
      return this.$store.getters.eventExpress
    }
  }
})
</script>

<style scoped>
.entrance-title {
  padding-top: 17px;
  padding-bottom: 8px;
  font-size: 17px;
  text-align: center;
}
.entrance-body {
  min-height: 100%;
  padding-bottom: 60px;
  position: relative;
}
.entrance-content {
  padding-top: 50px;
}
.entrance-content p {
  margin-bottom: 28px;
  margin-top: 0;
}
.entrance-end-btn-body {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.entrance-end-btn {
  width: 232px;
  height: 41px;
  background-color: #e05193;
  border-radius: 4px;
  margin: 0 auto;
  color: #fff;
  display: block;
}
</style>
